import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const postFeedback = (postId, data) => {
  return client({
    method: "POST",
    url: PATHS.postFeedback.replace(":postId", postId),
    data,
  });
};

export default postFeedback;
