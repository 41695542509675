import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";
import {
  DEFAULT_POST_CURRENT_PAGE,
  DEFAULT_POST_FILTER,
  DEFAULT_POST_PAGE_SIZE,
  DEFAULT_POST_SORT,
} from "constants/post";

export const getPosts = (
  filter = DEFAULT_POST_FILTER,
  sort = DEFAULT_POST_SORT,
  page = DEFAULT_POST_CURRENT_PAGE,
  pageSize = DEFAULT_POST_PAGE_SIZE
) => {
  return client({
    method: "GET",
    url: `${PATHS.postListing}?filter=${filter}&sort=${sort}&page=${page}&pageSize=${pageSize}`,
  });
};

export default getPosts;
