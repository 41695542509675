import { useAuth } from "context/AuthContext";
import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "assets/svg/logo.svg";
import { Avatar, Dropdown, Tabs } from "antd";
import { ReactComponent as UserIcon } from "assets/svg/user-icon.svg";
import { ReactComponent as PostIcon } from "assets/svg/post-icon.svg";
import { ReactComponent as ConfigurationIcon } from "assets/svg/configuration-icon.svg";
import { ReactComponent as SettingIcon } from "assets/svg/setting-icon.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PATHS } from "routes/paths";

const tabItems = [
  {
    key: "1",
    label: "Users",
    icon: <UserIcon />,
  },
  {
    key: "2",
    label: "Posts",
    icon: <PostIcon />,
  },
  {
    key: "3",
    label: "Configuration",
    icon: <ConfigurationIcon />,
  },

  {
    key: "4",
    label: "Settings",
    icon: <SettingIcon />,
  },
];

export const Navbar = ({ activeTab = tabItems[0].key }) => {
  const { user, logout } = useAuth();
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const navigate = useNavigate();

  return (
    <div className={styles.navbar}>
      <div className={styles.logo}>
        <Logo />
      </div>
      <div className={styles.tabs}>
        <Tabs
          activeKey={selectedTab}
          defaultActiveKey={tabItems[0].key}
          items={tabItems.map((item) => ({
            key: item.key,
            label: (
              <div className={styles.item}>
                {item.icon}
                <div className={styles.text}>{item.label}</div>
              </div>
            ),
          }))}
          onChange={(activeKey) => {
            setSelectedTab(activeKey);
          }}
          onTabClick={(key) => {
            switch (key) {
              case tabItems[0].key:
                navigate(PATHS.home);
                break;
              case tabItems[1].key:
                navigate(PATHS.posts);
                break;
              case tabItems[2].key:
                navigate(PATHS.configuration);
                break;
              case tabItems[3].key:
                navigate(PATHS.settings);
                break;
              default:
                navigate(PATHS.home);
                break;
            }
          }}
        />
      </div>
      <div>
        <Dropdown
          menu={{
            items: [
              {
                key: 1,
                label: <div className={styles.dropdownItem}>Logout</div>,
                onClick: logout,
              },
            ],
          }}
          trigger={["click"]}
          arrow
        >
          <Avatar
            size={40}
            src={user?.profileImg}
            className={styles.profileImg}
          >
            {user?.name?.slice(0, 2)}
          </Avatar>
        </Dropdown>
      </div>
    </div>
  );
};

export default Navbar;
