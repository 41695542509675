export const PATHS = {
  login: "/api/auth/admin/login",
  verifyAdmin: "/api/auth/admin/verify",
  listSpecialities: "/api/data/specialities",
  addNewSpeciality: "/api/data/speciality/create",
  deleteSpecificSpeciality: "/api/data/speciality/:id",
  updateSpeciality: "/api/data/speciality/:id",
  listRoles: "/api/data/roles",
  addNewRole: "/api/data/role/create",
  deleteSpecificRole: "/api/data/role/:id",
  updateRole: "/api/data/role/:id",
  getSettings: "/api/globalSettings",
  updateSettings: "/api/globalSetting/update",
  userList: "/api/users",
  getSpecificUser: "/api/profile/:username",
  updateUser: "/api/profile/update",
  getPost: "/api/post/:postId",
  updatePost: "/api/post/:postId",
  postFeedback: "/api/post/:postId/feedback",
  postListing: "/api/posts",
  refreshSession: "/api/auth/refresh",
};

export default PATHS;
