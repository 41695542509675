import { Button, Checkbox, Form, Select } from "antd";
import Modal from "antd/es/modal/Modal";
import useAllRoles from "hooks/useAllRoles";
import useAllSpecialities from "hooks/useAllSpecialities";
import { useEffect, useReducer } from "react";
import styles from "./styles.module.scss";
import {
  FILTER_DEFAULT_ACCOUNT_STATUS,
  FILTER_DEFAULT_COUNTRY_VALUE,
  FILTER_DEFAULT_VERIFIED_STATUS,
  FILTER_DEFAULT_SEPECIALITY_VALUE,
  FILTER_DEFAULT_ROLE_VALUE,
  ACCOUNT_STATUS,
  VERIFIED_STATUS,
} from "constants/users";
import { Country } from "country-state-city";

const initialValues = {
  accountStatus: FILTER_DEFAULT_ACCOUNT_STATUS,
  verifiedStatus: FILTER_DEFAULT_VERIFIED_STATUS,
  country: FILTER_DEFAULT_COUNTRY_VALUE,
  role: FILTER_DEFAULT_ROLE_VALUE,
  speciality: FILTER_DEFAULT_SEPECIALITY_VALUE,
};
const ACTION_TYPE = {
  update: "UPDATE",
  reset: "RESET",
};
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPE.update:
      return { ...state, ...action.payload };
    case ACTION_TYPE.reset:
      return initialValues;
    default:
      return state;
  }
}

export const ApplyUserFilter = ({
  open,
  loading,
  cancelText = "Reset",
  okText = "Apply",
  onOk,
  applyFilters,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  const {
    data: specialityData = { data: { data: [] } },
    isLoading: specialityLoading,
  } = useAllSpecialities();
  const { data: rolesData = { data: { data: [] } }, isLoading: roleLoading } =
    useAllRoles();

  const resetFields = () => {
    dispatch({ type: ACTION_TYPE.reset });
  };
  useEffect(() => {
    if (!specialityLoading && !roleLoading) {
      dispatch({
        type: ACTION_TYPE.update,
        payload: {
          accountStatus: applyFilters?.accountStatus,
          verifiedStatus: applyFilters?.verifiedStatus,
          country: applyFilters?.country,
          speciality:
            applyFilters?.speciality && !isNaN(applyFilters?.speciality)
              ? parseInt(applyFilters.speciality)
              : "any",
          role:
            applyFilters?.role && !isNaN(applyFilters?.role)
              ? parseInt(applyFilters.role)
              : "any",
        },
      });
    }
  }, [applyFilters, specialityLoading, roleLoading]);

  return (
    <Modal open={open} footer={null} width={400} {...props}>
      <Form
        className={styles.applyFilter}
        onFinish={() => {
          if (onOk) {
            onOk({
              speciality: state.speciality,
              role: state.role,
              country: state.country,
              accountStatus: state.accountStatus,
              verifiedStatus: state.verifiedStatus,
            });
          }
        }}
      >
        <div className={styles.form}>
          <div className={styles.select}>
            <div className={styles.label}>Specialty</div>
            <Select
              defaultValue={FILTER_DEFAULT_SEPECIALITY_VALUE}
              value={state.speciality}
              options={[
                {
                  value: "any",
                  label: "Any",
                },
                ...specialityData.data.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              ]}
              onChange={(value) =>
                dispatch({
                  type: ACTION_TYPE.update,
                  payload: { speciality: value },
                })
              }
            />
          </div>

          <div className={styles.select}>
            <div className={styles.label}>Role</div>
            <Select
              defaultValue={FILTER_DEFAULT_ROLE_VALUE}
              value={state.role}
              options={[
                {
                  value: "any",
                  label: "Any",
                },
                ...rolesData.data.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              ]}
              onChange={(value) =>
                dispatch({
                  type: ACTION_TYPE.update,
                  payload: { role: value },
                })
              }
            />
          </div>

          <div className={styles.select}>
            <div className={styles.label}>Account Status</div>
            <div className={styles.checkboxGroup}>
              {ACCOUNT_STATUS.map((item) => (
                <Checkbox
                  name="accountStatus"
                  defaultChecked={item.value === "any"}
                  key={item.value}
                  value={item.value}
                  checked={state.accountStatus === item.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch({
                        type: ACTION_TYPE.update,
                        payload: { accountStatus: item.value },
                      });
                    }
                  }}
                >
                  {item.label}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className={styles.select}>
            <div className={styles.label}>Verified</div>
            <div className={styles.checkboxGroup}>
              {VERIFIED_STATUS.map((item) => (
                <Checkbox
                  name="verifiedStatus"
                  defaultChecked={item.value === "any"}
                  key={item.value}
                  value={item.value}
                  checked={state.verifiedStatus === item.value}
                  onChange={(e) => {
                    if (e.target.checked) {
                      dispatch({
                        type: ACTION_TYPE.update,
                        payload: { verifiedStatus: item.value },
                      });
                    }
                  }}
                >
                  {item.label}
                </Checkbox>
              ))}
            </div>
          </div>

          <div className={styles.select}>
            <div className={styles.label}>Country</div>
            <Select
              defaultValue={FILTER_DEFAULT_COUNTRY_VALUE}
              showSearch
              value={state.country}
              options={[
                {
                  label: "Any",
                  value: "any",
                },
                ...Country.getAllCountries().map((item) => ({
                  label: item.name,
                  value: item.name,
                })),
              ]}
              onChange={(value) =>
                dispatch({
                  type: ACTION_TYPE.update,
                  payload: { country: value },
                })
              }
            />
          </div>
        </div>

        <div className={styles.btnBlock}>
          <Button disabled={loading} block size="middle" onClick={resetFields}>
            {cancelText}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="middle"
          >
            {okText}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ApplyUserFilter;
