import { ExclamationCircleFilled, LeftOutlined } from "@ant-design/icons";
import { Button, Modal, message, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { POST_STATUS } from "constants/post";
import usePostFeedback from "hooks/usePostFeedback";
import useUpdatePost from "hooks/useUpdatePost";
import styles from "pages/Post/styles.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { adminFeedbackSchema } from "schemas/Post/adminFeedback";
import { yupSync } from "utils/validateSchema";

export const PostHeader = ({ postId, status }) => {
  const navigate = useNavigate();
  const [feedbackForm] = Form.useForm();
  const { mutateAsync, isLoading } = useUpdatePost();
  const [showAddFeedbackModal, setShowAddFeedbackModal] = useState({
    show: false,
    target: POST_STATUS.unApproved,
  });
  const [modal, contextHolder] = Modal.useModal();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const { mutateAsync: postFeedback, isLoading: feedbackLoading } =
    usePostFeedback();

  const onSubmitFeedback = async (values) => {
    try {
      await postFeedback({
        postId,
        feedback: values.feedbackText?.replace(/^\s+|\s+$/g, ""),
        status: showAddFeedbackModal.target,
      });
      await mutateAsync({ postId, status: showAddFeedbackModal.target });
      closeAddFeedbackModal();
    } catch (error) {
      message.error("Error in providing the feedback");
    }
  };

  const confirmModal = (title, msg, onOk) => {
    setShowConfirmModal(true);
    if (!showConfirmModal) {
      modal.confirm({
        title: title,
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        content: msg,
        centered: true,
        loading: isLoading,
        onOk,
        onCancel: () => setShowConfirmModal(false),
      });
    }
  };
  const openAddFeedbackModal = (_status) =>
    setShowAddFeedbackModal({ show: true, target: _status });
  const closeAddFeedbackModal = () => {
    feedbackForm.resetFields();
    setShowAddFeedbackModal((s) => ({ ...s, show: false }));
  };

  const feedbackYupSync = yupSync(adminFeedbackSchema);

  return (
    <div className={styles.header}>
      <div className={styles.back}>
        <div onClick={() => navigate(-1)}>
          <LeftOutlined />
        </div>
        <div className="pageTitle">Post details</div>
      </div>
      <div className={styles.actionBtns}>
        {status !== POST_STATUS.removed && (
          <Button
            type="primary"
            className={styles.needChangeBtn}
            onClick={() => openAddFeedbackModal(POST_STATUS.unApproved)}
          >
            Request revision
          </Button>
        )}
        {status !== POST_STATUS.approved && (
          <Button
            type="primary"
            onClick={() => {
              confirmModal(
                "Approve Post",
                "Are you sure you want to approve this post?",
                async () => {
                  try {
                    await mutateAsync({ postId, status: POST_STATUS.approved });
                    message.success("Successfully approved");
                  } catch (error) {
                    message.error("Error in approving this Post");
                  } finally {
                    setShowConfirmModal(false);
                  }
                }
              );
            }}
          >
            Approve
          </Button>
        )}
        {status === POST_STATUS.removed ? (
          <Button
            type="primary"
            danger
            onClick={() => {
              confirmModal(
                "Reinstate Post",
                "Are you sure you want to reinstate this post?",
                async () => {
                  try {
                    await mutateAsync({ postId, status: POST_STATUS.inReview });
                    message.success("Successfully reinstate post");
                  } catch (error) {
                    message.error("Error in approving this Post");
                  } finally {
                    setShowConfirmModal(false);
                  }
                }
              );
            }}
          >
            Reinstate
          </Button>
        ) : (
          <>
            {status !== POST_STATUS.unApproved && (
              <Button
                type="primary"
                danger
                onClick={() => {
                  openAddFeedbackModal(POST_STATUS.removed);
                }}
              >
                Remove
              </Button>
            )}
          </>
        )}
      </div>
      <Modal
        title="Add Feedback"
        open={showAddFeedbackModal.show}
        onCancel={closeAddFeedbackModal}
        centered
        okText="Submit"
        onOk={onSubmitFeedback}
        footer={null}
      >
        <Form
          form={feedbackForm}
          initialValues={{
            feedbackText: "",
          }}
          name="feedbackForm"
          className={styles.feedbackForm}
          onFinish={onSubmitFeedback}
        >
          <Form.Item name="feedbackText" rules={[feedbackYupSync]}>
            <TextArea placeholder="write feedback here..." rows={5} />
          </Form.Item>
          <div className={styles.btnBlock}>
            <Button
              disabled={isLoading || feedbackLoading}
              block
              size="middle"
              onClick={closeAddFeedbackModal}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading || feedbackLoading}
              block
              size="middle"
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal>
      {contextHolder}
    </div>
  );
};

export default PostHeader;
