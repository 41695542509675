import DashboardLayout from "layout/DashboardLayout";
import styles from "./styles.module.scss";
import { Badge, Button, Input, Select, Tabs } from "antd";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { useDeferredValue, useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "assets/svg/filter-icon.svg";
import {
  DEFAULT_POST_CURRENT_PAGE,
  DEFAULT_POST_PAGE_SIZE,
  DEFAULT_POST_SORT,
  DEFAULT_POST_SORT_ORDER,
  POST_STATUS,
} from "constants/post";
import { Pagination, Table } from "ui-components";
import usePosts from "hooks/usePosts";
import dayjs from "dayjs";
import { useNavigate, useSearchParams } from "react-router-dom";
import PATHS from "routes/paths";
import ApplyPostFilter from "components/Modal/ApplyPostFilter";
import { deleteQueryParameter, updateQueryParameter } from "utils/queryParams";
import { DEFAULT_DATE_FORMAT } from "constants/date";

const tabItems = [
  {
    key: POST_STATUS.unApproved,
    label: "Need Revision",
  },
  {
    key: POST_STATUS.inReview,
    label: "In Review",
  },
  {
    key: POST_STATUS.approved,
    label: "Approved",
  },
  {
    key: POST_STATUS.removed,
    label: "Removed",
  },
];

const prepareFilterStr = (obj) => {
  let finalFilter = {
    status: `status=${POST_STATUS.approved}`,
  };
  if (obj.status) {
    finalFilter.status = `status=${obj.status}`;
  }
  if (obj?.title) {
    finalFilter.title = `title=${obj.title}`;
  }
  if (obj?.speciality !== "any") {
    finalFilter.speciality = `speciality=${obj.speciality}`;
  }
  if (obj?.author && obj?.author !== "any") {
    finalFilter.author = `createdBy=${obj.author}`;
  }
  const finalFilterArr = Object.values(finalFilter);
  if (finalFilterArr.length === 0) return "";
  return finalFilterArr.join(",");
};

const sortTableStr = (
  by = DEFAULT_POST_SORT,
  order = DEFAULT_POST_SORT_ORDER
) => {
  if (order === "asc") {
    return by;
  } else if (order === "desc") {
    return `-${by}`;
  }
};

export const PostListing = () => {
  const [searchParams] = useSearchParams();

  const [searchText, setSearchText] = useState(
    searchParams.get("searchText") || ""
  );
  const deferredSearchText = useDeferredValue(searchText);
  const [applyFilers, setApplyFilters] = useState({
    status: searchParams.get("status") || POST_STATUS.unApproved,
    title: encodeURIComponent(searchParams.get("searchText")) || "",
    speciality:
      searchParams.get("speciality") && !isNaN(searchParams.get("speciality"))
        ? searchParams.get("speciality")
        : "any",
    author: searchParams.get("author") || "",
  });
  const [totalFilters, setTotalFilters] = useState(0);
  const [showFilter, setShowFilter] = useState(false);
  const [sort, setSort] = useState(
    sortTableStr(
      searchParams.get("sortBy") || DEFAULT_POST_SORT,
      searchParams.get("sortOrder") || DEFAULT_POST_SORT_ORDER
    )
  );
  const [sortBy, setSortBy] = useState(
    searchParams.get("sortBy") || DEFAULT_POST_SORT
  );
  const [sortOrder, setSortOrder] = useState(
    searchParams.get("sortOrder") || DEFAULT_POST_SORT_ORDER
  );
  const [selectedTab, setSelectedTab] = useState(
    searchParams.get("status") || POST_STATUS.unApproved
  );
  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") && !isNaN(searchParams.get("page"))
      ? parseInt(searchParams.get("page"))
      : DEFAULT_POST_CURRENT_PAGE
  );
  const navigate = useNavigate();
  const { data, isLoading } = usePosts(
    prepareFilterStr(applyFilers),
    sort,
    currentPage,
    selectedTab,
    DEFAULT_POST_PAGE_SIZE
  );

  const sortTable = (
    by = DEFAULT_POST_SORT,
    order = DEFAULT_POST_SORT_ORDER
  ) => {
    if (order === "asc") {
      setSort(by);
    } else if (order === "desc") {
      setSort(`-${by}`);
    }
  };

  const columns = [
    {
      title: "Post Title",
      dataIndex: "title",
      key: "title",
      className: styles.postTitleColumn,
      render: (val) => {
        return <div className={styles.postTitle}>{val}</div>;
      },
    },
    {
      title: "Post Specialty",
      dataIndex: "speciality",
      key: "speciality",
    },
    {
      title: "Post Author",
      dataIndex: "author",
      key: "author",
    },
    {
      title: "Total Votes",
      dataIndex: "totalVotes",
      key: "totalVotes",
      className: styles.totalVotesColumn,
      render: (val) => {
        return <div className={styles.textCenter}>{val}</div>;
      },
    },
    {
      title: "Date Added",
      dataIndex: "dateAdded",
      key: "dateAdded",
      className: styles.dateAddedColumn,
      render: (val) => {
        return <div className={styles.textCenter}>{val}</div>;
      },
    },
    {
      title: "Flagged",
      dataIndex: "inappropriateFlags",
      key: "inappropriateFlags",
      className: styles.inappropriateFlagColumn,
      render: (val) => {
        return <div className={styles.textCenter}>{val}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, item) => {
        return (
          <div className={styles.textCenter}>
            <EyeOutlined />
          </div>
        );
      },
    },
  ];

  const closeFilterModal = () => setShowFilter(false);

  const showFilterModal = () => {
    setApplyFilters((s) => ({ ...s }));
    setShowFilter(true);
  };
  const onApplyFilter = (values) => {
    const filterCount = Object.values(values).filter(
      (item) => !!item && item !== "any"
    ).length;
    deleteQueryParameter("page");
    setCurrentPage(DEFAULT_POST_CURRENT_PAGE);
    if (values?.speciality !== "any") {
      updateQueryParameter("speciality", values.speciality);
    } else {
      deleteQueryParameter("speciality");
    }
    if (values?.author) {
      updateQueryParameter("author", values.author);
    } else {
      deleteQueryParameter("author");
    }
    setTotalFilters(filterCount);
    setApplyFilters((s) => ({ ...s, ...values }));
    closeFilterModal();
  };
  const onRowClick = (row) => {
    navigate(PATHS.post.replace(":postId", row.key));
  };
  useEffect(() => {
    const acceptedFilters = ["speciality", "author"];
    setTotalFilters(
      Object.keys(applyFilers).filter(
        (item) =>
          acceptedFilters.includes(item) &&
          applyFilers[item] &&
          applyFilers[item] !== "any"
      ).length
    );
  }, []);
  useEffect(() => {
    setApplyFilters((s) => ({
      ...s,
      title: encodeURIComponent(deferredSearchText),
    }));
  }, [deferredSearchText]);
  return (
    <DashboardLayout activeTab="2">
      <div className={styles.postListing}>
        <div className={styles.header}>
          <div className="pageTitle">Posts Management</div>
          <div className={styles.options}>
            <div className={styles.filter}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search post title"
                size="middle"
                value={searchText}
                onChange={(e) => {
                  updateQueryParameter("searchText", e.target.value);
                  setCurrentPage(DEFAULT_POST_CURRENT_PAGE);
                  setSearchText(e.target.value);
                }}
                allowClear
              />
              <Badge count={totalFilters}>
                <Button
                  icon={<FilterIcon />}
                  className={styles.filter_btn}
                  onClick={showFilterModal}
                >
                  Filter
                </Button>
              </Badge>
            </div>
            <div className={styles.sort}>
              <div className={styles.select}>
                <div className={styles.label}>Sort by</div>
                <Select
                  style={{ width: "10.5rem" }}
                  defaultValue="name"
                  value={sortBy}
                  options={[
                    {
                      value: "totalVotes",
                      label: "Total Votes",
                    },
                    {
                      value: "inappropriate-flags",
                      label: "Inappropriate Flags",
                    },
                    {
                      value: "createdAt",
                      label: "Date",
                    },
                  ]}
                  onChange={(e) => {
                    updateQueryParameter("sortBy", e);
                    deleteQueryParameter("page");
                    setCurrentPage(DEFAULT_POST_CURRENT_PAGE);
                    setSortBy(e);
                    sortTable(e, sortOrder);
                  }}
                />
              </div>
              <div className={styles.select}>
                <div className={styles.label}>Sort order</div>
                <Select
                  style={{ width: "8rem" }}
                  defaultValue={sortOrder}
                  labelInValue="sort by"
                  options={[
                    {
                      value: "asc",
                      label: "Ascending",
                    },
                    {
                      value: "desc",
                      label: "Descending",
                    },
                  ]}
                  value={sortOrder}
                  onChange={({ value }) => {
                    updateQueryParameter("sortOrder", value);
                    deleteQueryParameter("page");
                    setCurrentPage(DEFAULT_POST_CURRENT_PAGE);
                    setSortOrder(value);
                    sortTable(sortBy, value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.tabs}>
          <Tabs
            activeKey={selectedTab}
            defaultActiveKey={tabItems[0].key}
            items={tabItems.map((item) => ({
              key: item.key,
              label: (
                <div className={styles.item}>
                  {item.icon}
                  <div className={styles.text}>{item.label}</div>
                </div>
              ),
            }))}
            centered
            onChange={(activeKey) => {
              setCurrentPage(DEFAULT_POST_CURRENT_PAGE);
              deleteQueryParameter("page");
              updateQueryParameter("status", activeKey);
              setSelectedTab(activeKey);
              setApplyFilters((s) => ({ ...s, status: activeKey }));
            }}
          />
        </div>
        <div className={styles.main}>
          <Table
            columns={columns}
            dataSource={data?.data?.data?.map((item) => ({
              key: item.id,
              title: item.title,
              speciality: item.speciality,
              author: item?.createdBy?.name,
              dateAdded: dayjs(item.createdAt).format(DEFAULT_DATE_FORMAT),
              totalVotes: item?.totalVotes,
              inappropriateFlags: item?.totalInappropriatePosts,
            }))}
            defaultSkeletonRows={DEFAULT_POST_PAGE_SIZE}
            loading={isLoading}
            onRowClick={onRowClick}
          />
          <div className={styles.pagination}>
            <Pagination
              current={currentPage}
              total={data?.data?.totalPosts}
              showSizeChanger={false}
              onChange={(pagination) => {
                updateQueryParameter("page", pagination);
                setCurrentPage(parseInt(pagination));
              }}
              pageSize={DEFAULT_POST_PAGE_SIZE}
            />
          </div>
        </div>
        <ApplyPostFilter
          open={showFilter}
          onCancel={closeFilterModal}
          title="Apply Filters"
          centered
          onOk={onApplyFilter}
          applyFilters={{
            speciality: applyFilers.speciality,
            author: applyFilers?.author,
          }}
        />
      </div>
    </DashboardLayout>
  );
};

export default PostListing;
