import { POST_STATUS } from "constants/post";

export const getPostStatusLabel = (status) => {
  switch (status) {
    case POST_STATUS.approved:
      return {
        label: "Approved",
        className: "primary-text badge primary-badge",
      };
    case POST_STATUS.inReview:
      return {
        label: "In Review",
        className: "primary-text badge primary-badge",
      };
    case POST_STATUS.unApproved:
      return {
        label: "Needs revision",
        className: "yellow-text badge yellow-badge",
      };
    case POST_STATUS.removed:
      return {
        label: "Removed",
        className: "danger-text badge danger-badge",
      };
    default:
      return {
        label: "Approved",
        className: "primary-text badge primary-badge",
      };
  }
};

export default getPostStatusLabel;
