import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const getPost = async (postId) => {
  return client({
    method: "GET",
    url: PATHS.getPost.replace(":postId", postId),
  });
};

export default getPost;
