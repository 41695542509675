import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";

export const getRoles = ({
  pageSize,
  page,
  filter = "",
  sort = "-createdAt,name",
}) => {
  let url = `${PATHS.listRoles}?filter=${filter}&sort=${sort}`;
  if (pageSize && page) {
    url = `${url}&pageSize=${pageSize}&page=${page}`;
  }
  return client({
    method: "GET",
    url,
  });
};

export default getRoles;
