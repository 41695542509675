import { useQuery } from "@tanstack/react-query";
import { getSpecialities } from "services";
import {
  DEFAULT_FILTER,
  DEFAULT_SPECIALITY_CURRENT_PAGE,
  DEFAULT_SPECIALITY_PAGE_SIZE,
} from "constants/configuration";

export const useSpecialities = (
  filter = DEFAULT_FILTER,
  currentPage = DEFAULT_SPECIALITY_CURRENT_PAGE,
  pageSize = DEFAULT_SPECIALITY_PAGE_SIZE
) => {
  return useQuery(["specialities", filter, currentPage, pageSize], () => {
    return getSpecialities({
      pageSize,
      page: currentPage,
      filter,
    }).then((res) => res.data);
  });
};

export default useSpecialities;
