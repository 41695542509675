import dayjs from "dayjs";
import styles from "pages/Post/styles.module.scss";
import { getPostStatusLabel } from "utils/getPostStatusLabel";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "constants/date";

export const AdminFeedbacks = ({ adminFeedbacks }) => {
  return (
    <div className={styles.adminFeedbacks}>
      <div className={styles.feedbackTitle}>Admin Feedback</div>
      <div className={styles.feedbacks}>
        {adminFeedbacks.map((item) => {
          const status = getPostStatusLabel(item.status);
          return (
            <div className={styles.feedbackItem} key={item.id}>
              <div className={styles.date}>
                <span className={status.className}>{status.label}</span>
                <span>
                  {dayjs(item.createdAt).format(DEFAULT_DATE_FORMAT)}-
                  {dayjs(item.createdAt).format(DEFAULT_TIME_FORMAT)}
                </span>
              </div>
              <div
                className={styles.feedbackContent}
                dangerouslySetInnerHTML={{
                  __html: item.feedback?.replaceAll("\n", "<br />"),
                }}
              ></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AdminFeedbacks;
