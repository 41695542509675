import { Avatar } from "antd";
import styles from "pages/User/styles.module.scss";
import { ReactComponent as UrlIcon } from "assets/svg/url.svg";

export const UserHeaderInfo = ({
  profileImg,
  name,
  role,
  linkedInLink,
  title,
  speciality,
}) => {
  return (
    <div className={styles.head}>
      <div className={styles.cover} />
      <div className={styles.detail}>
        <div className={styles.avatar}>
          <Avatar
            src={profileImg}
            style={{
              backgroundColor: profileImg ? "#fff" : "#A8A8A8",
            }}
            size="large"
          >
            <span className={styles.text}>{name?.[0]}</span>
          </Avatar>
        </div>
        <div className={styles.personal}>
          <div className={styles.name}>
            {name?.trim()}
            {title && <strong>{`, ${title}`}</strong>}
          </div>
          {role && (
            <div className={styles.role}>
              {role}
              {speciality && `, ${speciality}`}
            </div>
          )}
          {linkedInLink && (
            <div
              className={styles.linkedIn}
              onClick={() => window.open(linkedInLink, "_blank")}
            >
              LinkedIn
              <span className={styles.icon}>
                <UrlIcon />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserHeaderInfo;
