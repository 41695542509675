import { Table as AntTable, Popover, Skeleton } from "antd";
import styles from "./styles.module.scss";
import classNames from "classnames";
import Button from "ui-components/Button";
import { LockOutlined } from "@ant-design/icons";

export const Table = ({
  dataSource,
  padlocked,
  loading,
  defaultSkeletonRows = 50,
  tableClassName = "",
  needEvent = false,
  onRowClickWithEvent,
  onRowClick,
  onRowHover,
  onLeaveRow,
  isHover = false,
  isCustomColorExist = false,
  displayColor = false,
  displayLock = false,
  isCustomIconExist = false,
  pagination = {},
  isPagination = false,
  ...props
}) => {
  const renderCustomColor = (val, rowData) => {
    return (
      <div>
        {displayColor ? (
          <div
            style={{
              background:
                rowData["name"] === "Complete"
                  ? "#5cd828"
                  : rowData["name"] === "no-show"
                  ? "#f15d3b"
                  : rowData.color || rowData?.icon_color,
            }}
            className={styles.customColor}
          />
        ) : (
          <div
            style={{ background: rowData.color }}
            className={styles.customColor}
          />
        )}
      </div>
    );
  };

  const renderActiveButton = (isActive) => {
    if (isActive === "") return;
    return (
      <Button
        className={isActive ? styles.activeBtn : styles.disableSourceBtn}
        disabled={!isActive}
      >
        {isActive ? "Active" : "Inactive"}
      </Button>
    );
  };
  const renderLockIcon = (isLock) => {
    return (
      <div className={styles.lockIcon}>
        {isLock ? <LockOutlined color="#B8B8C0" /> : null}
      </div>
    );
  };
  const renderHoverContent = (val, rowData) => {
    let display = "1";
    if (!rowData.isShow) {
      display = "0";
    }
    return (
      <div
        key={rowData.key}
        className={styles.renderHoverWrap}
        style={{ opacity: display }}
      >
        {rowData?.visibleData}
      </div>
    );
  };
  const renderMessageHover = (val) => {
    if (val?.length > 60) {
      return (
        <Popover trigger="hover" content={val}>
          {val.slice(0, 60)}...
        </Popover>
      );
    }
    return <span>{val}</span>;
  };

  const renderCodeInput = (code) => {
    return (
      <Button type="dashed" className={styles.codeBtn}>
        {code}
      </Button>
    );
  };
  const renderTableSource = (val, rowData) => {
    return (
      <div className={styles.alignItems}>
        {(isCustomColorExist || displayColor) &&
          renderCustomColor(val, rowData)}
        {val}
        {padlocked?.includes(val) && (
          <div style={{ marginLeft: "6px" }}>
            <LockOutlined />
          </div>
        )}
        {displayLock ? (
          rowData["basic_field"] ? (
            <div style={{ marginLeft: "6px" }}>
              <LockOutlined />
            </div>
          ) : null
        ) : null}
        {isCustomIconExist && rowData.icon && <>{rowData.icon}</>}
      </div>
    );
  };
  const renderSortHandler = () => {
    if (props?.columns) {
      props.columns = props.columns
        ?.filter((col) => col.visible !== false)
        .map((col) => {
          if (col?.render) {
            return col;
          } else if (
            col &&
            (col.dataIndex === "public" ||
              col.dataIndex === "is_active" ||
              col.dataIndex === "integration")
          ) {
            col.render = renderActiveButton;
          } else if (col.dataIndex === "is_lock") {
            col.render = renderLockIcon;
          } else if (col.isHover) {
            col.render = renderHoverContent;
          } else
            switch (col.dataIndex) {
              case "messageHover": {
                col.render = renderMessageHover;

                break;
              }
              case "code": {
                col.render = renderCodeInput;

                break;
              }
              case "addColumn": {
                break;
              }
              default: {
                if (!col.render) {
                  col.render = renderTableSource;
                }
              }
            }
          return col;
        });
    }

    return props.columns;
  };

  const onHoverLeaveHandle = (data) => {
    onLeaveRow?.(data);
  };

  const onHoverEnterHandle = (data) => {
    onRowHover?.(data);
  };

  const checkPadLocks = (record) => {
    let clickable = true;
    Object.keys(record).map((key) => {
      if (padlocked?.includes(record[key])) {
        clickable = false;
      }
      return key;
    });
    return clickable;
  };

  const renderAvatarLoader = () => {
    return <Skeleton.Avatar active size="default" shape="circle" />;
  };

  if (loading && props?.columns) {
    const columns = [];
    const dataSource = [];
    for (const key of props?.columns) {
      const { visible = true, columnType = "" } = key;
      delete key.onCell;
      delete key.onHeaderCell;
      if (visible) {
        if (columnType === "avatar") {
          columns.push({ ...key, render: renderAvatarLoader });
        } else {
          columns.push({
            ...key,
            render: function render() {
              const width = key.skeletonWidth ?? "200px";
              return (
                <div className={styles.columnLoader} style={{ width: width }}>
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ width: width, minWidth: width }}
                  />
                </div>
              );
            },
          });
        }
      }
    }
    for (let i = 0; i < defaultSkeletonRows; i = i + 1) {
      let data;
      for (const key of props?.columns) {
        data = { ...data, id: i, key: i, [key.dataIndex]: "" };
      }
      dataSource.push(data);
    }

    return (
      <AntTable
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        columns={columns}
        rowKey="key"
        pagination={false}
        className={classNames({
          [styles.dragTable]: !tableClassName,
          [tableClassName]: !!tableClassName,
        })}
      />
    );
  }

  return (
    <AntTable
      {...props}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            if (checkPadLocks(record)) {
              needEvent
                ? onRowClickWithEvent?.(record, event)
                : onRowClick?.(record);
            }
          },
          onMouseEnter: (event) => {
            isHover && onHoverEnterHandle(record);
          },
          onMouseLeave: (event) => {
            isHover && onHoverLeaveHandle(record);
          },
        };
      }}
      pagination={
        isPagination ? { position: ["bottomCenter"], ...pagination } : false
      }
      dataSource={dataSource}
      columns={renderSortHandler()}
      rowKey="key"
      className={classNames({
        [styles.dragTable]: !tableClassName,
        [tableClassName]: !!tableClassName,
      })}
    />
  );
};

export default Table;
