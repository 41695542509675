import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editPost } from "services";

export const useUpdatePost = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, ...data }) => editPost(postId, data).then((res) => res.data),
    {
      onSuccess: (e, { postId, ...data }) => {
        queryClient.setQueryData(["post", postId], (old) => {
          const updatedData = {
            ...old,
            data: {
              ...old.data,
              ...data,
            },
          };

          return updatedData;
        });
      },
    }
  );
};

export default useUpdatePost;
