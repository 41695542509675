import { message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewSpeciality } from "services";

export const useAddSpeciality = () => {
  const queryClient = useQueryClient();
  return useMutation((name) => addNewSpeciality(name).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["specialities"],
      });
    },
    onError: (err) => {
      if (err?.response?.status === 400 && err?.response?.data?.message) {
        message.error(err.response.data.message);
      } else {
        message.error("Error in creating the specialty");
      }
    },
  });
};

export default useAddSpeciality;
