import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Switch } from "antd";
import useUpdateUser from "hooks/useUpdateUser";
import styles from "pages/User/styles.module.scss";
import { useState } from "react";

export const UserSettings = ({ userId, active, verified, postScreening }) => {
  const [modal, contextHolder] = Modal.useModal();
  const { isLoading, mutateAsync } = useUpdateUser();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteConfirm = (title, msg, onOk, onCancel) => {
    setShowConfirmModal(true);
    if (!showConfirmModal) {
      modal.confirm({
        title: title,
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        content: msg,
        centered: true,
        loading: isLoading,
        onOk,
        onCancel() {
          setShowConfirmModal(false);
          if (onCancel) {
            onCancel();
          }
        },
      });
    }
  };

  return (
    <div className={styles.settings}>
      <div className={styles.title}>Settings</div>
      <div className={styles.content}>
        <div className={styles.item}>
          <div className={styles.label}>Is Active</div>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={active}
            checked={active}
            onChange={(val) => {
              showDeleteConfirm(
                `${active ? "Suspend" : "Active"} User`,
                `Do you want to ${active ? "suspend" : "activate"} this user?`,
                async () => {
                  try {
                    await mutateAsync({ active: val, userId });
                  } catch (error) {
                  } finally {
                    setShowConfirmModal(false);
                  }
                }
              );
            }}
          />
        </div>
        <div className={styles.item}>
          <div className={styles.label}>Is Verified</div>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={verified}
            checked={verified}
            onChange={(val) => {
              showDeleteConfirm(
                `${verified ? "Unverify" : "Verify"} User`,
                `Do you want to ${verified ? "unverify" : "verify"} this user?`,

                async () => {
                  try {
                    await mutateAsync({ verified: val, userId });
                  } catch (error) {
                  } finally {
                    setShowConfirmModal(false);
                  }
                }
              );
            }}
          />
        </div>

        <div className={styles.item}>
          <div className={styles.label}>Post Screening required</div>
          <Switch
            checkedChildren="Yes"
            unCheckedChildren="No"
            defaultChecked={postScreening}
            checked={postScreening}
            onChange={(val) => {
              showDeleteConfirm(
                `Turn ${postScreening ? "off" : "on"} Post Screening Setting`,
                `Do you want to turn ${
                  postScreening ? "off" : "on"
                } the Post screening setting of this user?`,
                async () => {
                  try {
                    await mutateAsync({ POST_SCREENING: val, userId });
                  } catch (error) {
                  } finally {
                    setShowConfirmModal(false);
                  }
                }
              );
            }}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
};

export default UserSettings;
