import { useMutation, useQueryClient } from "@tanstack/react-query";
import { postFeedback } from "services";

export const usePostFeedback = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ postId, ...data }) => postFeedback(postId, data).then((res) => res.data),
    {
      onSuccess: (e, { postId }) => {
        queryClient.setQueryData(["post", postId], (old) => {
          const updatedData = {
            ...old,
            data: {
              ...old.data,
              adminFeedbacks: [e.data, ...old.data?.adminFeedbacks],
            },
          };
          return updatedData;
        });
      },
    }
  );
};

export default usePostFeedback;
