import { useQuery } from "@tanstack/react-query";
import { getSpecialities } from "services";

export const useAllSpecialities = () => {
  return useQuery(["AllSpecialities"], () =>
    getSpecialities({ sort: "name" }).then((res) => res.data)
  );
};

export default useAllSpecialities;
