export const DEFAULT_USER_PAGE_SIZE = 10;
export const DEFAULT_USER_CURRENT_PAGE = 1;
export const DEFAULT_USER_FILTER = "userRole=user";
export const DEFAULT_USER_SORT = "name";
export const DEFAULT_SORT_ORDER = "asc";

export const ACCOUNT_STATUS = [
  {
    label: "Any",
    value: "any",
  },
  {
    label: "Active",
    value: "active",
  },
  {
    label: "Suspended",
    value: "suspended",
  },
];

export const VERIFIED_STATUS = [
  {
    label: "Any",
    value: "any",
  },
  {
    label: "Verified",
    value: "verified",
  },
  {
    label: "Unverified",
    value: "unverified",
  },
];

export const FILTER_DEFAULT_SEPECIALITY_VALUE = "any";
export const FILTER_DEFAULT_COUNTRY_VALUE = "any";
export const FILTER_DEFAULT_ACCOUNT_STATUS = ACCOUNT_STATUS[0].value;
export const FILTER_DEFAULT_VERIFIED_STATUS = VERIFIED_STATUS[0].value;
export const FILTER_DEFAULT_ROLE_VALUE = "any";
