import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const refreshSession = (refreshToken) => {
  return client({
    method: "POST",
    url: PATHS.refreshSession,
    data: {
      refreshToken,
    },
  });
};

export default refreshSession;
