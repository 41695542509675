import { message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteRole } from "services";

export const useDeleteRole = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteRole(id).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["roles"],
      });
    },
    onError: (err) => {
      message.error(
        err?.response?.data?.message || "Error in deleting the role"
      );
    },
  });
};

export default useDeleteRole;
