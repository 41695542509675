import { Spin } from "antd";
import { AdminFeedbacks } from "components/Post/AdminFeedbacks";
import { PostDetails } from "components/Post/PostDetails";
import { PostHeader } from "components/Post/PostHeader";
import usePost from "hooks/usePost";
import DashboardLayout from "layout/DashboardLayout";
import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";

export const Post = () => {
  const { postId } = useParams();
  const { data, isLoading } = usePost(postId);

  return (
    <DashboardLayout activeTab="2">
      {isLoading ? (
        <div className="absCenter">
          <Spin tip="Loading" size="large" />
        </div>
      ) : data?.data ? (
        <div className={styles.post}>
          <PostHeader postId={postId} status={data?.data?.status} />
          <div className={styles.main}>
            <div className={styles.head} />
            <PostDetails
              content={data.data?.content}
              createdAt={data.data?.createdAt}
              createdBy={data.data?.createdBy}
              speciality={data.data?.speciality}
              title={data?.data?.title}
              totalDownVotes={data?.data?.totalDownVotes}
              totalUpVotes={data?.data?.totalUpVotes}
              totalInappropriatePosts={data?.data?.totalInappropriatePosts}
              status={data?.data?.status}
              topic={data?.data?.topic}
            />

            <AdminFeedbacks adminFeedbacks={data.data.adminFeedbacks} />
            <div className={styles.postDetailBottom} />
          </div>
        </div>
      ) : (
        <div className={styles.postErrorMsg}>Post doesn't exist</div>
      )}
    </DashboardLayout>
  );
};

export default Post;
