import * as yup from "yup";

export const addConfigurationSchema = yup.object().shape({
  input: yup
    .string()
    .trim()
    .test(
      "no-numbers",
      "This field should not contain any numbers",
      function (value) {
        return !/\d/.test(value);
      }
    )
    .required("Please fill out this field"),
});

export default addConfigurationSchema;
