import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const addNewSpeciality = (name) => {
  return client({
    method: "POST",
    url: PATHS.addNewSpeciality,
    data: {
      name,
    },
  });
};

export default addNewSpeciality;
