import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateGlobalSettings } from "services";
import { message } from "antd";

export const useSaveSetting = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (settings) => updateGlobalSettings(settings).then((res) => res.data),
    {
      onMutate: (settings) => {
        queryClient.setQueryData(["globalSettings"], (old) => {
          const updatedSettings = {
            ...old,
            data: {
              ...old.data,
              ...settings,
            },
          };
          return updatedSettings;
        });
      },
      onError: (err) => {
        message.error("Error in updating the settings");
      },
    }
  );
};

export default useSaveSetting;
