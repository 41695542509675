import { LeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import useUser from "hooks/useUser";
import DashboardLayout from "layout/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import { PersonalDetails } from "components/User/PersonalDetails";
import { UserHeaderInfo } from "components/User/UserHeaderInfo";
import { UserSettings } from "components/User/UserSettings";
import styles from "./styles.module.scss";

const OTHER_ROLE = "Other Non-Healthcare Professional";

export const User = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useUser(userId);

  return (
    <DashboardLayout activeTab="1">
      {isLoading ? (
        <div className="absCenter">
          <Spin tip="Loading" size="large" />
        </div>
      ) : data?.data ? (
        <div className={styles.user}>
          <div className={styles.header}>
            <div className={styles.back}>
              <div onClick={() => navigate(-1)}>
                <LeftOutlined />
              </div>
              <div className="pageTitle">User details</div>
            </div>
          </div>

          <div className={styles.main}>
            <div className={styles.info}>
              <UserHeaderInfo
                linkedInLink={data.data?.linkedInLink}
                name={data.data?.name}
                profileImg={data.data?.profileImg}
                role={
                  data.data?.role === OTHER_ROLE
                    ? data?.data?.customRole || data.data?.role
                    : data.data?.role
                }
                title={data?.data?.title}
                speciality={data?.data?.speciality}
              />
              <PersonalDetails
                country={data.data?.country}
                email={data.data?.email}
                institutionalAffiliation={data.data?.institutionalAffiliation}
                state={data.data?.state}
                totalVotes={data.data?.totalVotes || 0}
                yearsInPractice={data.data?.yearsInPractice}
              />
            </div>
            <UserSettings
              active={data.data?.active}
              verified={data.data?.verified}
              postScreening={data.data?.POST_SCREENING}
              userId={userId}
            />
          </div>
        </div>
      ) : (
        <div className={styles.userErrorMsg}>User doesn't exist</div>
      )}
    </DashboardLayout>
  );
};

export default User;
