import "./antd.scss";
import { ProtectedRoute } from "routes/ProtectedRoute";
import { Routes, Route } from "react-router-dom";
import { routes } from "routes";
import "./App.scss";

function App() {
  return (
    <Routes>
      {routes.map((item) => (
        <Route
          exact
          path={item.path}
          key={item.path}
          element={<ProtectedRoute route={item}>{item.element}</ProtectedRoute>}
        />
      ))}
    </Routes>
  );
}

export default App;
