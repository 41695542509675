import { Pagination as AntPagination } from "antd";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const Pagination = ({ customClass = "", ...props }) => {
  return (
    <div className={classNames(styles.tableFooter, customClass)}>
      <AntPagination
        className={styles.tblPagination}
        showSizeChanger={false}
        defaultCurrent={1}
        defaultPageSize={10}
        {...props}
      />
    </div>
  );
};
