import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const login = (email, password) => {
  return client.post(PATHS.login, {
    email,
    password,
  });
};

export default login;
