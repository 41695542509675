import { useQuery } from "@tanstack/react-query";
import { getPost } from "services/backend";

export const usePost = (postId) => {
  return useQuery(["post", postId], () =>
    getPost(postId).then((res) => res.data)
  );
};

export default usePost;
