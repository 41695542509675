import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { login as userLogin } from "services";
import { verifyAdmin } from "services";
import { PATHS } from "routes/paths";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const verifyToken = async () => {
    if (localStorage.getItem("token")) {
      setIsLoading(true);
      try {
        const data = await verifyAdmin();
        setUser(data.data?.data);
      } catch (error) {
        console.log("user is not loggedIn");
      }
    }
    setIsLoading(false);
  };
  useEffect(() => {
    verifyToken();
  }, []);

  const login = async (email, password) => {
    try {
      const data = await userLogin(email, password);
      localStorage.setItem("token", data.data?.data?.AccessToken);
      localStorage.setItem("refreshToken", data.data?.data?.RefreshToken);
      const userData = await verifyAdmin();
      setUser(userData.data?.data);
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("token");
    navigate(PATHS.login);
    setUser(null);
  };
  return (
    <AuthContext.Provider value={{ login, user, isLoading, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
