import { useQuery } from "@tanstack/react-query";
import { getGlobalSetting } from "services";

export const useSetting = () => {
  return useQuery(["globalSettings"], () =>
    getGlobalSetting().then((res) => res.data)
  );
};

export default useSetting;
