import styles from "pages/User/styles.module.scss";
import classNames from "classnames";

export const UserDetailItem = ({ label, value }) => {
  return (
    <div className={styles.userDetailItem}>
      <div className={styles.label}>{label}</div>
      <div
        className={classNames([
          styles.val,
          {
            [styles.nullVal]: value === null,
          },
        ])}
      >
        {value !== null ? value : "N/A"}
      </div>
    </div>
  );
};

export const PersonalDetails = ({
  email,
  country,
  institutionalAffiliation,
  state,
  totalVotes,
  yearsInPractice,
}) => {
  return (
    <div className={styles.personalDetails}>
      <UserDetailItem label="Email" value={email} />
      <UserDetailItem
        label="Institutional Affiliation"
        value={institutionalAffiliation || null}
      />
      <UserDetailItem
        label="Years in Practice"
        value={
          yearsInPractice
            ? `${yearsInPractice} ${yearsInPractice > 1 ? "years" : "year"}`
            : null
        }
      />

      <UserDetailItem label="Country" value={country || null} />

      <UserDetailItem label="State" value={state || null} />

      <UserDetailItem label="Total Votes" value={totalVotes || 0} />
    </div>
  );
};

export default PersonalDetails;
