import { Input, Form } from "antd";
import Modal from "antd/es/modal/Modal";
import { Button } from "ui-components";
import styles from "./styles.module.scss";
import { useEffect, useRef } from "react";
import { addConfigurationSchema } from "schemas/configuration/addConfigurationSchema";
import { yupSync } from "utils/validateSchema";

export const AddConfigurationModal = ({
  title = "Add",
  open,
  onOk,
  onCancel,
  okText = "Add",
  cancelText = "Cancel",
  label,
  placeholder = "",
  loading = false,
  inputDefaultValue = "",
  ...props
}) => {
  const form = useRef(null);
  const onClose = () => {
    onCancel();
    form.current.resetFields();
  };

  const configurationYupSync = yupSync(addConfigurationSchema);

  useEffect(() => {
    if (form.current) {
      form.current.setFieldValue("input", inputDefaultValue);
    }
  }, [inputDefaultValue, form]);

  return (
    <Modal
      title={title}
      open={open}
      {...props}
      footer={null}
      onCancel={onClose}
    >
      <Form
        onFinish={(values) => onOk(values, form)}
        layout="vertical"
        className={styles.addConfigurationForm}
        ref={form}
        initialValues={{
          input: inputDefaultValue?.trim(),
        }}
      >
        <Form.Item
          label={label}
          name="input"
          rules={[configurationYupSync]}
          className={styles.item}
        >
          <Input size="large" placeholder={placeholder} />
        </Form.Item>
        <div className={styles.btnBlock}>
          <Button onClick={onClose} disabled={loading}>
            {cancelText}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {okText}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddConfigurationModal;
