import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import dayjs from "dayjs";
import styles from "pages/Post/styles.module.scss";
import classNames from "classnames";
import getPostStatusLabel from "utils/getPostStatusLabel";
import { DEFAULT_DATE_FORMAT } from "constants/date";

export const PostDetails = ({
  createdBy,
  createdAt,
  title,
  speciality,
  content,
  totalUpVotes = 0,
  totalDownVotes = 0,
  totalInappropriatePosts = 0,
  status,
  topic,
}) => {
  const postStatus = getPostStatusLabel(status);
  return (
    <div className={styles.content}>
      <div className={styles.content__user}>
        <div className={styles.user}>
          <Avatar src={createdBy?.profileImg} size="large">
            {createdBy.name[0]}
          </Avatar>
          <div className={styles.name}>
            {createdBy.name}
            {createdBy?.title && `, ${createdBy.title}`}
          </div>
        </div>
        <div className={styles.date}>
          <span>{dayjs(createdAt).format(DEFAULT_DATE_FORMAT)}</span>
        </div>
      </div>
      <div className={styles.postDetails}>
        <div className={styles.item}>
          <div className={styles.title}>Post Title</div>
          <div className={styles.postTitle}>{title}</div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>Post Status</div>
          <div className={classNames([styles.status, postStatus.className])}>
            {postStatus.label}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>Post Specialty</div>
          <div
            className={classNames([
              styles.speciality,
              {
                [styles.notFound]: !speciality,
              },
            ])}
          >
            {speciality ? speciality : "N/A"}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>Post Topic</div>
          <div
            className={classNames([
              styles.speciality,
              {
                [styles.notFound]: !topic,
              },
            ])}
          >
            {topic ? topic?.name : "N/A"}
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.title}>Post Details</div>
          <div
            className={styles.postContent}
            dangerouslySetInnerHTML={{
              __html: content?.replaceAll("\n", "<br />"),
            }}
          />
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.flag}>
          <div className={styles.flagName}>Up Votes</div>
          <div className={styles.flagVal}>
            {totalUpVotes}
            <span>
              <ArrowUpOutlined />
            </span>
          </div>
        </div>

        <div className={styles.flag}>
          <div className={styles.flagName}>Down Votes</div>
          <div className={styles.flagVal}>
            {totalDownVotes}
            <span>
              <ArrowDownOutlined />
            </span>
          </div>
        </div>

        <div className={styles.flag}>
          <div className={styles.flagName}>Flags</div>
          <div className={styles.flagVal}>{totalInappropriatePosts}</div>
        </div>
      </div>
    </div>
  );
};

export default PostDetails;
