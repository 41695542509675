import { Navbar } from "layout/DashboardLayout/Navbar";
import styles from "./styles.module.scss";

export const DashboardLayout = ({ activeTab, children }) => {
  return (
    <div className={styles.dashboardLayout}>
      <Navbar activeTab={activeTab} />
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default DashboardLayout;
