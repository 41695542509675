import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const getGlobalSetting = () => {
  return client({
    method: "GET",
    url: PATHS.getSettings,
  });
};

export default getGlobalSetting;
