import { Tabs } from "antd";
import DashboardLayout from "layout/DashboardLayout";
import styles from "./styles.module.scss";
import Speciality from "pages/Configuration/Speciality";
import Role from "pages/Configuration/Role";
import { useState } from "react";

export const Configuration = () => {
  const [activeTab, setActiveTab] = useState(1);
  return (
    <DashboardLayout activeTab="3">
      <div className={styles.configuration}>
        <div className={styles.tabs}>
          <Tabs
            items={[
              {
                key: 1,
                label: "Specialty",
              },
              {
                key: 2,
                label: "Role",
              },
            ]}
            centered
            size="middle"
            activeKey={activeTab}
            onChange={(val) => setActiveTab(parseInt(val))}
          />
        </div>
        {activeTab === 1 && <Speciality />}
        {activeTab === 2 && <Role />}
      </div>
    </DashboardLayout>
  );
};

export default Configuration;
