import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, message } from "antd";
import { AddConfigurationModal } from "components/Modal/AddConfigurationModal";
import { useDeferredValue, useState } from "react";
import { Button, Pagination, Table } from "ui-components";
import { useRoles } from "hooks/useRoles";
import { useAddRole } from "hooks/useAddRole";
import { useUpdateRole } from "hooks/useUpdateRole";
import { useDeleteRole } from "hooks/useDeleteRole";
import styles from "./styles.module.scss";
import {
  DEFAULT_ROLE_CURRENT_PAGE,
  DEFAULT_ROLE_PAGE_SIZE,
} from "constants/configuration";
import { useQueryClient } from "@tanstack/react-query";

export const Role = () => {
  const [addRoleModalShow, setAddRoleModalShow] = useState(false);
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, deleteModalContextHolder] = Modal.useModal();
  const [searchText, setSearchText] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const deferredSearchText = useDeferredValue(searchText);
  const openAddRoleModal = () => setAddRoleModalShow(true);
  const { data: paginatedData, isLoading } = useRoles(
    `${encodeURIComponent(deferredSearchText)}`,
    currentPage,
    DEFAULT_ROLE_PAGE_SIZE
  );
  const { isLoading: minorUpdateLoading, mutateAsync: editRole } =
    useUpdateRole();
  const { mutateAsync: createRole, isLoading: minorLoading } = useAddRole();
  const { isLoading: minorDeleteLoading, mutateAsync: deleteRole } =
    useDeleteRole();
  const closeAddRoleModal = () => {
    setAddRoleModalShow(false);
    setSelectedItem(null);
  };

  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "18rem",
      render: (item, rowData) => {
        return (
          <div className={styles.actionColumn}>
            <Button
              type="primary"
              danger
              onClick={() => {
                setShowConfirmModal(true);
                if (!showConfirmModal) {
                  deleteModal.confirm({
                    title: "Delete Role",
                    icon: <ExclamationCircleOutlined />,
                    content: "Are you sure you want to delete this role?",
                    okText: "Yes",
                    cancelText: "No",
                    centered: true,
                    loading: minorDeleteLoading,
                    onCancel: () => setShowConfirmModal(false),
                    onOk: async () => {
                      try {
                        await deleteRole(rowData?.id);
                        message.success("Successfully deleted");
                      } catch (error) {
                        console.log("Error in deleting the role");
                      } finally {
                        setShowConfirmModal(false);
                      }
                    },
                  });
                }
              }}
            >
              Delete
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setSelectedItem(rowData);
                setAddRoleModalShow(true);
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const onAddNewRole = async (values, form) => {
    const inputValue = values.input?.trim();
    try {
      if (selectedItem) {
        await editRole({ id: selectedItem?.id, name: inputValue });
        queryClient.setQueryData(
          ["roles", deferredSearchText, currentPage, DEFAULT_ROLE_PAGE_SIZE],
          (old) => {
            const index = old.data.data.findIndex(
              (item) => item.id === selectedItem?.id
            );
            old.data.data[index].name = values.input;
            return old;
          }
        );
        message.success("Successfully updated");
      } else {
        await createRole(values.input?.trim());
        message.success("Successfully created");
      }
      form.current.resetFields();
      closeAddRoleModal();
      setSelectedItem(null);
    } catch (error) {
      console.log("error in performing this error");
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className="pageTitle">Configuration</div>
        <div className={styles.filter}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            size="middle"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(DEFAULT_ROLE_CURRENT_PAGE);
            }}
            allowClear
          />
          <Button type="primary" size="middle" onClick={openAddRoleModal}>
            Add New Role
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          columns={columns}
          dataSource={paginatedData?.data?.data?.map((item) => {
            return {
              key: item?.id,
              id: item?.id,
              role: item?.name,
            };
          })}
          defaultSkeletonRows={DEFAULT_ROLE_PAGE_SIZE}
          loading={isLoading}
        />
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            total={paginatedData?.data?.total}
            showSizeChanger={false}
            onChange={(pagination) => {
              setCurrentPage(parseInt(pagination));
            }}
            pageSize={DEFAULT_ROLE_PAGE_SIZE}
          />
        </div>
      </div>
      <AddConfigurationModal
        title={`${selectedItem ? "Edit" : "Add"} Role`}
        okText="Save"
        onCancel={closeAddRoleModal}
        open={addRoleModalShow}
        cancelText="Cancel"
        label="Role"
        placeholder={`${selectedItem ? "Edit" : "Add"} new Role`}
        onOk={onAddNewRole}
        loading={minorLoading || minorUpdateLoading}
        selectedItem={selectedItem}
        inputDefaultValue={selectedItem?.role || ""}
        centered
      />
      {deleteModalContextHolder}
    </>
  );
};

export default Role;
