import { Spin } from "antd";
import { useAuth } from "context/AuthContext";
import UserListing from "pages/UserListing";
import { Navigate } from "react-router-dom";
import { PATHS } from "routes/paths";

export const ProtectedRoute = ({ route, children }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return (
      <div className="absCenter">
        <Spin tip="Loading" size="large" />
      </div>
    );
  }
  if (route?.protected && !user) return <Navigate to={PATHS.login} />;
  if (!route?.protected && user) return <UserListing />;
  return children;
};

export default ProtectedRoute;
