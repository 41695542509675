export const PATHS = {
  login: "/",
  home: "/home",
  posts: "/posts",
  configuration: "/configuration",
  settings: "/settings",
  user: "/user/:userId",
  post: "/post/:postId",
};

export default PATHS;
