import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";

export const getSpecificUser = async (userId) => {
  return client({
    method: "GET",
    url: PATHS.getSpecificUser.replace(":username", userId),
  });
};

export default getSpecificUser;
