import { PATHS } from "routes/paths";
import { Login } from "pages/Login";
import { UserListing } from "pages/UserListing";
import { PostListing } from "pages/PostListing";
import { Configuration } from "pages/Configuration";
import { Setting } from "pages/Setting";
import { User } from "pages/User";
import { Post } from "pages/Post";

export const routes = [
  {
    path: PATHS.login,
    element: <Login />,
  },
  {
    path: PATHS.home,
    element: <UserListing />,
    protected: true,
  },
  {
    path: PATHS.posts,
    element: <PostListing />,
    protected: true,
  },
  {
    path: PATHS.configuration,
    element: <Configuration />,
    protected: true,
  },
  {
    path: PATHS.settings,
    element: <Setting />,
    protected: true,
  },
  {
    path: PATHS.user,
    element: <User />,
    protected: true,
  },
  {
    path: PATHS.post,
    element: <Post />,
    protected: true,
  },
];

export default routes;
