// Function to add or update a query parameter in the URL
export const updateQueryParameter = (key, value) => {
  const searchParams = new URLSearchParams(window.location.search);

  // Check if the parameter already exists
  if (searchParams.has(key)) {
    // Update the existing parameter
    searchParams.set(key, value);
  } else {
    // Add a new parameter
    searchParams.append(key, value);
  }

  // Replace the current URL with the updated query parameters
  window.history.replaceState({}, "", `?${searchParams.toString()}`);
};

// Function to delete a query parameter from the URL
export const deleteQueryParameter = (key) => {
  const searchParams = new URLSearchParams(window.location.search);

  // Check if the parameter exists
  if (searchParams.has(key)) {
    // Delete the parameter
    searchParams.delete(key);

    // Replace the current URL with the updated query parameters
    window.history.replaceState({}, "", `?${searchParams.toString()}`);
  }
};
