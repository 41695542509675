import { message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { editRole } from "services";

export const useUpdateRole = () => {
  return useMutation(
    ({ id, name }) => editRole(id, name).then((res) => res.data),
    {
      onError: (err) => {
        if (err?.response?.status === 400 && err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error("Error in updating the role");
        }
      },
    }
  );
};

export default useUpdateRole;
