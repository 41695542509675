import { useQuery } from "@tanstack/react-query";
import { getRoles } from "services";
import {
  DEFAULT_ROLE_FILTER,
  DEFAULT_ROLE_CURRENT_PAGE,
  DEFAULT_ROLE_PAGE_SIZE,
} from "constants/configuration";

export const useRoles = (
  filter = DEFAULT_ROLE_FILTER,
  currentPage = DEFAULT_ROLE_CURRENT_PAGE,
  pageSize = DEFAULT_ROLE_PAGE_SIZE
) => {
  return useQuery(["roles", filter, currentPage, pageSize], () => {
    return getRoles({ pageSize, page: currentPage, filter }).then(
      (res) => res.data
    );
  });
};

export default useRoles;
