import { message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import deleteSpeciality from "services/backend/apis/deleteSpeciality";

export const useDeleteSpeciality = () => {
  const queryClient = useQueryClient();

  return useMutation((id) => deleteSpeciality(id).then((res) => res.data), {
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: ["specialities"],
      });
    },
    onError: (err) => {
      message.error(
        err?.response?.data?.message || "Error in deleting the specialty"
      );
    },
  });
};
