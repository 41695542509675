import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const editRole = (id, name) => {
  return client({
    method: "PATCH",
    url: PATHS.updateRole.replace(":id", id),
    data: {
      name,
    },
  });
};

export default editRole;
