import { useQuery } from "@tanstack/react-query";
import { getSpecificUser } from "services/backend";

export const useUser = (userId) => {
  return useQuery(["user", userId], () =>
    getSpecificUser(userId).then((res) => res.data)
  );
};

export default useUser;
