import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Input, Select, Tag } from "antd";
import { ReactComponent as FilterIcon } from "assets/svg/filter-icon.svg";
import { ReactComponent as InCorrect } from "assets/svg/in-correct.svg";
import { ReactComponent as Tick } from "assets/svg/tick.svg";
import {
  DEFAULT_USER_CURRENT_PAGE,
  DEFAULT_USER_PAGE_SIZE,
  DEFAULT_USER_SORT,
  FILTER_DEFAULT_ACCOUNT_STATUS,
  FILTER_DEFAULT_COUNTRY_VALUE,
  FILTER_DEFAULT_VERIFIED_STATUS,
  FILTER_DEFAULT_SEPECIALITY_VALUE,
  FILTER_DEFAULT_ROLE_VALUE,
  DEFAULT_SORT_ORDER,
} from "constants/users";
import { useUsers } from "hooks/useUsers";
import { DashboardLayout } from "layout/DashboardLayout";
import { useDeferredValue, useEffect, useState } from "react";
import { Pagination, Table } from "ui-components";
import styles from "./styles.module.scss";
import ApplyUserFilter from "components/Modal/ApplyUserFilter";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PATHS } from "routes/paths";
import { deleteQueryParameter, updateQueryParameter } from "utils/queryParams";
import dayjs from "dayjs";
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from "constants/date";
import { APP_LABELS } from "constants/app";

const prepareFilterStr = (obj) => {
  let finalFilter = ["userRole=user"];
  if (obj.verifiedStatus !== "any") {
    finalFilter.push(
      `verified=${obj.verifiedStatus === "verified" ? "true" : "false"}`
    );
  }
  if (obj.accountStatus !== "any") {
    finalFilter.push(
      `active=${obj.accountStatus === "active" ? "true" : "false"}`
    );
  }
  if (obj.country !== "any") {
    finalFilter.push(`country=${obj.country}`);
  }
  if (obj.speciality !== "any") {
    finalFilter.push(`speciality=${obj.speciality}`);
  }
  if (obj.role !== "any") {
    finalFilter.push(`role=${obj.role}`);
  }
  if (obj?.name) {
    finalFilter.push(`name=${obj.name}`);
  }
  if (finalFilter.length === 0) return "";
  return finalFilter.join(",");
};

const sortTableStr = (by = DEFAULT_USER_SORT, order = DEFAULT_SORT_ORDER) => {
  if (order === "asc") {
    return by;
  } else if (order === "desc") {
    return `-${by}`;
  }
};

export const UserListing = () => {
  const [searchParams] = useSearchParams();
  const [applyFilers, setApplyFilters] = useState({
    speciality:
      searchParams.get("speciality") || FILTER_DEFAULT_SEPECIALITY_VALUE,
    role: searchParams.get("role") || FILTER_DEFAULT_ROLE_VALUE,
    country: searchParams.get("country") || FILTER_DEFAULT_COUNTRY_VALUE,
    accountStatus:
      searchParams.get("accountStatus") || FILTER_DEFAULT_ACCOUNT_STATUS,
    verifiedStatus:
      searchParams.get("verifiedStatus") || FILTER_DEFAULT_VERIFIED_STATUS,
    name: encodeURIComponent(searchParams.get("searchText")) || "",
  });

  const [currentPage, setCurrentPage] = useState(
    searchParams.get("page") && !isNaN(searchParams.get("page"))
      ? parseInt(searchParams.get("page"))
      : DEFAULT_USER_CURRENT_PAGE
  );
  const [sort, setSort] = useState(
    sortTableStr(
      searchParams.get("sortBy") || DEFAULT_USER_SORT,
      searchParams.get("sortOrder") || DEFAULT_SORT_ORDER
    )
  );
  const [sortBy, setSortBy] = useState(
    searchParams.get("sortBy") || DEFAULT_USER_SORT
  );
  const [searchText, setSearchText] = useState(
    searchParams.get("searchText") || ""
  );
  const deferredSearchText = useDeferredValue(searchText);
  const [showFilter, setShowFilter] = useState(false);
  const [totalFilters, setTotalFilters] = useState(0);
  const [sortOrder, setSortOrder] = useState(
    searchParams.get("sortOrder") || DEFAULT_SORT_ORDER
  );
  const navigation = useNavigate();
  const { data, isLoading } = useUsers(
    prepareFilterStr(applyFilers),
    sort,
    currentPage,
    DEFAULT_USER_PAGE_SIZE
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (val, item) => {
        return (
          <div className={styles.userNameColumn}>
            <Avatar src={item?.profileImg} size="large">
              {val[0]?.toUpperCase()}
            </Avatar>
            <div className={styles.nameInfo}>
              <div className={styles.name}>{val}</div>
              <div className={styles.email}>{item?.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Specialty",
      dataIndex: "speciality",
      key: "speciality",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Account Status",
      dataIndex: "accountStatus",
      key: "accountStatus",
      width: "150px",
      render: (val) => {
        if (val) {
          return (
            <Tag bordered={false} color="success">
              Active
            </Tag>
          );
        }
        return (
          <Tag bordered={false} color="red">
            Suspended
          </Tag>
        );
      },
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      width: "80px",
      render: (val) => {
        if (val) {
          return <Tick />;
        }
        return <InCorrect />;
      },
    },
    {
      title: "Inappropriate Flags",
      dataIndex: "inappropriateFlags",
      key: "inappropriateFlags",
      className: styles.inappropriateFlagColumn,
      render: (val) => {
        return <div className={styles.textCenter}>{val}</div>;
      },
    },
    {
      title: "App",
      dataIndex: "appName",
      key: "dataIndex",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (val) => {
        return (
          <div className={styles.textCenter}>
            {dayjs(val).format(DEFAULT_DATE_FORMAT)}-
            {dayjs(val).format(DEFAULT_TIME_FORMAT)}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",
      render: (_, item) => {
        return (
          <div className={styles.textCenter}>
            <EyeOutlined />
          </div>
        );
      },
    },
  ];

  const showFilterModal = () => {
    setApplyFilters((s) => ({ ...s }));
    setShowFilter(true);
  };
  const closeFilterModal = () => setShowFilter(false);
  const onApplyFilter = (values) => {
    Object.keys(values).forEach((item) => {
      if (values[item] !== "any") {
        updateQueryParameter(item, values[item]);
      } else {
        deleteQueryParameter(item);
      }
    });
    const filterCount = Object.values(values).filter(
      (item) => item !== "any"
    ).length;

    deleteQueryParameter("page");
    setCurrentPage(DEFAULT_USER_CURRENT_PAGE);
    setApplyFilters((s) => ({ ...s, ...values }));
    setTotalFilters(filterCount);
    closeFilterModal();
  };
  useEffect(() => {
    setApplyFilters((s) => ({
      ...s,
      name: encodeURIComponent(deferredSearchText),
    }));
  }, [deferredSearchText]);
  useEffect(() => {
    const acceptedFilters = [
      "speciality",
      "role",
      "verifiedStatus",
      "accountStatus",
      "country",
    ];
    setTotalFilters(
      Object.keys(applyFilers).filter(
        (item) =>
          acceptedFilters.includes(item) &&
          applyFilers[item] &&
          applyFilers[item] !== "any"
      ).length
    );
  }, []);

  const sortTable = (by = DEFAULT_USER_SORT, order = DEFAULT_SORT_ORDER) => {
    if (order === "asc") {
      setSort(by);
    } else if (order === "desc") {
      setSort(`-${by}`);
    }
  };
  const onRowClick = (row) => {
    navigation(PATHS.user.replace(":userId", row.key));
  };
  return (
    <DashboardLayout activeTab="1">
      <div className={styles.userListing}>
        <div className={styles.header}>
          <div className="pageTitle">Users</div>
          <div className={styles.options}>
            <div className={styles.filter}>
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search user name"
                size="middle"
                value={searchText}
                onChange={(e) => {
                  updateQueryParameter("searchText", e.target.value);
                  setCurrentPage(DEFAULT_USER_CURRENT_PAGE);
                  setSearchText(e.target.value);
                }}
                allowClear
              />
              <Badge count={totalFilters}>
                <Button
                  icon={<FilterIcon />}
                  className={styles.filter_btn}
                  onClick={showFilterModal}
                >
                  Filter
                </Button>
              </Badge>
            </div>
            <div className={styles.sort}>
              <div className={styles.select}>
                <div className={styles.label}>Sort by</div>
                <Select
                  style={{ width: "10.5rem" }}
                  defaultValue="name"
                  value={sortBy}
                  options={[
                    {
                      value: "name",
                      label: "Name",
                    },
                    {
                      value: "inappropriate-flags",
                      label: "Inappropriate Flags",
                    },
                  ]}
                  onChange={(e) => {
                    deleteQueryParameter("page");
                    updateQueryParameter("sortBy", e);
                    setCurrentPage(DEFAULT_USER_CURRENT_PAGE);
                    setSortBy(e);
                    sortTable(e, sortOrder);
                  }}
                />
              </div>
              <div className={styles.select}>
                <div className={styles.label}>Sort order</div>
                <Select
                  style={{ width: "8rem" }}
                  defaultValue={sortOrder}
                  labelInValue="sort by"
                  options={[
                    {
                      value: "asc",
                      label: "Ascending",
                    },
                    {
                      value: "desc",
                      label: "Descending",
                    },
                  ]}
                  value={sortOrder}
                  onChange={({ value }) => {
                    deleteQueryParameter("page");
                    updateQueryParameter("sortOrder", value);
                    setCurrentPage(DEFAULT_USER_CURRENT_PAGE);
                    setSortOrder(value);
                    sortTable(sortBy, value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.main}>
          <Table
            columns={columns}
            dataSource={data?.data?.data?.map((item) => ({
              key: item.id,
              name: item?.name,
              speciality: item?.speciality,
              role: item?.role,
              country: item?.country,
              accountStatus: item?.active,
              verified: item?.verified,
              inappropriateFlags: item?.totalInappropriatePosts || 0,
              email: item?.email,
              profileImg: item?.profileImg,
              appName: APP_LABELS[item?.app?.name] || item?.app?.name,
              createdAt: item?.createdAt,
            }))}
            defaultSkeletonRows={DEFAULT_USER_PAGE_SIZE}
            loading={isLoading}
            onRowClick={onRowClick}
          />
          <div className={styles.pagination}>
            <Pagination
              current={currentPage}
              total={data?.data?.total}
              showSizeChanger={false}
              onChange={(pagination) => {
                updateQueryParameter("page", pagination);
                setCurrentPage(parseInt(pagination));
              }}
              pageSize={DEFAULT_USER_PAGE_SIZE}
            />
          </div>
        </div>
      </div>
      <ApplyUserFilter
        open={showFilter}
        onCancel={closeFilterModal}
        title="Apply Filters"
        centered
        onOk={onApplyFilter}
        applyFilters={applyFilers}
      />
    </DashboardLayout>
  );
};

export default UserListing;
