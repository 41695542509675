import { Button as AntButton } from "antd";

export const Button = ({
  className,
  disabled,
  color,
  loading = false,
  backgroundColor,
  children,
  style,
  ...props
}) => {
  return (
    <AntButton
      style={{ ...style, backgroundColor: backgroundColor }}
      disabled={disabled}
      color={color}
      loading={loading}
      className={className}
      {...props}
    >
      {children}
    </AntButton>
  );
};

export default Button;
