import { Button, Form, Select, Spin } from "antd";
import Modal from "antd/es/modal/Modal";
import useAllSpecialities from "hooks/useAllSpecialities";
import { useEffect, useReducer, useState } from "react";
import styles from "./styles.module.scss";
import {
  FILTER_DEFAULT_SEPECIALITY_VALUE,
  FILTER_DEFAULT_AUTHOR_VALUE,
} from "constants/post";
import useSearchUsers from "hooks/useSearchAuthor";

const initialValues = {
  speciality: FILTER_DEFAULT_SEPECIALITY_VALUE,
  author: FILTER_DEFAULT_AUTHOR_VALUE,
};
const ACTION_TYPE = {
  update: "UPDATE",
  reset: "RESET",
};
function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPE.update:
      return { ...state, ...action.payload };
    case ACTION_TYPE.reset:
      return initialValues;
    default:
      return state;
  }
}

export const ApplyPostFilter = ({
  open,
  loading,
  cancelText = "Reset",
  okText = "Apply",
  onOk,
  applyFilters,
  ...props
}) => {
  const [state, dispatch] = useReducer(reducer, initialValues);
  const {
    data: specialityData = { data: { data: [] } },
    isLoading: specialityLoading,
  } = useAllSpecialities();
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState(
    applyFilters?.author ? `id=${applyFilters?.author}` : ""
  );
  const { data: authorData, isLoading } = useSearchUsers(filter, "name", 1, 10);
  const resetFields = () => {
    dispatch({ type: ACTION_TYPE.reset });
  };
  useEffect(() => {
    if (!specialityLoading) {
      setSearchText(applyFilters?.author);
      dispatch({
        type: ACTION_TYPE.update,
        payload: {
          speciality:
            applyFilters?.speciality && !isNaN(applyFilters?.speciality)
              ? parseInt(applyFilters?.speciality)
              : "any",
          author: applyFilters?.author,
        },
      });
    }
  }, [applyFilters, specialityLoading]);

  return (
    <Modal open={open} footer={null} width={400} {...props}>
      <Form
        className={styles.applyFilter}
        onFinish={() => {
          if (onOk) {
            onOk({
              speciality: state.speciality,
              author: state.author || "",
            });
          }
        }}
      >
        <div className={styles.form}>
          <div className={styles.select}>
            <div className={styles.label}>Post Specialty</div>
            <Select
              defaultValue={FILTER_DEFAULT_SEPECIALITY_VALUE}
              value={state.speciality}
              options={[
                {
                  value: "any",
                  label: "Any",
                },
                ...specialityData.data.data.map((item) => ({
                  label: item.name,
                  value: item.id,
                })),
              ]}
              onChange={(value) =>
                dispatch({
                  type: ACTION_TYPE.update,
                  payload: { speciality: value },
                })
              }
            />
          </div>
          <div className={styles.select}>
            <div className={styles.label}>Post Author</div>
            <Select
              showSearch
              placeholder="Search Author"
              suffixIcon={null}
              defaultValue={applyFilters?.author}
              defaultActiveFirstOption={false}
              filterOption={false}
              value={state.author || null}
              notFoundContent={
                isLoading ? (
                  <div className={styles.selectLoading}>
                    <Spin size="small" />
                  </div>
                ) : null
              }
              options={
                searchText
                  ? authorData?.data?.data?.map?.((item) => ({
                      value: item.id,
                      label: item.name,
                    }))
                  : []
              }
              onSearch={(e) => {
                setSearchText(e);
                setFilter(`userRole=user,name=${e}`);
              }}
              onChange={(value) =>
                dispatch({
                  type: ACTION_TYPE.update,
                  payload: { author: value },
                })
              }
            />
          </div>
        </div>

        <div className={styles.btnBlock}>
          <Button disabled={loading} block size="middle" onClick={resetFields}>
            {cancelText}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            block
            size="middle"
          >
            {okText}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ApplyPostFilter;
