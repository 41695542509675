import { message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { editSpeciality } from "services";

export const useUpdateSpeciality = () => {
  return useMutation(
    ({ id, name }) => editSpeciality(id, name).then((res) => res.data),
    {
      onError: (err) => {
        if (err?.response?.status === 400 && err?.response?.data?.message) {
          message.error(err.response.data.message);
        } else {
          message.error("Error in updating the specialty");
        }
      },
    }
  );
};

export default useUpdateSpeciality;
