export const POST_STATUS = {
  inReview: "in-review",
  unApproved: "unapproved",
  approved: "approved",
  removed: "removed",
};

export const DEFAULT_POST_PAGE_SIZE = 10;
export const DEFAULT_POST_CURRENT_PAGE = 1;
export const DEFAULT_POST_FILTER = `equals('status', '${POST_STATUS.approved}'`;
export const DEFAULT_POST_SORT = "createdAt";
export const DEFAULT_POST_SORT_ORDER = "asc";

export const FILTER_DEFAULT_SEPECIALITY_VALUE = "any";
export const FILTER_DEFAULT_AUTHOR_VALUE = "";
