import { useQuery } from "@tanstack/react-query";
import { getPosts } from "services";
import {
  DEFAULT_POST_CURRENT_PAGE,
  DEFAULT_POST_PAGE_SIZE,
  DEFAULT_POST_FILTER,
  DEFAULT_POST_SORT,
  POST_STATUS,
} from "constants/post";

export const usePosts = (
  filter = DEFAULT_POST_FILTER,
  sort = DEFAULT_POST_SORT,
  currentPage = DEFAULT_POST_CURRENT_PAGE,
  selectedTab = POST_STATUS.approved,
  pageSize = DEFAULT_POST_PAGE_SIZE
) => {
  return useQuery(
    ["posts", filter, sort, currentPage, selectedTab, pageSize],
    () => {
      return getPosts(filter, sort, currentPage, pageSize).then(
        (res) => res.data
      );
    }
  );
};

export default usePosts;
