import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";
import {
  DEFAULT_USER_SORT,
  DEFAULT_USER_FILTER,
  DEFAULT_USER_CURRENT_PAGE,
  DEFAULT_USER_PAGE_SIZE,
} from "constants/users";

export const getUsers = (
  filter = DEFAULT_USER_FILTER,
  sort = DEFAULT_USER_SORT,
  page = DEFAULT_USER_CURRENT_PAGE,
  pageSize = DEFAULT_USER_PAGE_SIZE
) => {
  return client({
    method: "GET",
    url: `${PATHS.userList}?filter=${filter}&sort=${sort}&page=${page}&pageSize=${pageSize}`,
  });
};

export default getUsers;
