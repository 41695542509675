import { useQuery } from "@tanstack/react-query";
import { getRoles } from "services";

export const useAllRoles = () => {
  return useQuery(["AllRoles"], () =>
    getRoles({ sort: "order" }).then((res) => res.data)
  );
};

export default useAllRoles;
