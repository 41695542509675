import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const deleteSpeciality = (id) => {
  return client({
    method: "DELETE",
    url: PATHS.deleteSpecificSpeciality.replace(":id", id),
  });
};

export default deleteSpeciality;
