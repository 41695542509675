import { useQuery } from "@tanstack/react-query";
import { getUsers } from "services";
import {
  DEFAULT_USER_CURRENT_PAGE,
  DEFAULT_USER_PAGE_SIZE,
  DEFAULT_USER_FILTER,
  DEFAULT_USER_SORT,
} from "constants/users";

export const useUsers = (
  filter = DEFAULT_USER_FILTER,
  sort = DEFAULT_USER_SORT,
  currentPage = DEFAULT_USER_CURRENT_PAGE,
  pageSize = DEFAULT_USER_PAGE_SIZE
) => {
  return useQuery(["users", filter, sort, currentPage, pageSize], () => {
    return getUsers(filter, sort, currentPage, pageSize).then(
      (res) => res.data
    );
  });
};

export default useUsers;
