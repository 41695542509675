import styles from "./style.module.scss";
import { UnauthLayout } from "layout/UnauthLayout";
import { Card, Input, Form, message } from "antd";
import { Button } from "ui-components/Button";
import { useAuth } from "context/AuthContext";
import { useRef, useState } from "react";

export const Login = () => {
  const { login } = useAuth();
  const form = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values) => {
    setIsLoading(true);
    try {
      await login(values.email, values.password);
      form.current.resetFields();
    } catch (error) {
      message.error("Invalid email and password");
    }
    setIsLoading(false);
  };

  return (
    <UnauthLayout>
      <div className={styles.login}>
        <Card className={styles.card}>
          <div className={styles.content}>
            <div className={styles.header}>
              <div className={styles.title}>Welcome Admin!</div>
              <div className={styles.subTitle}>Login with your credentials</div>
            </div>
            <Form
              onFinish={onFinish}
              layout="vertical"
              className={styles.form}
              ref={form}
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Email is required" },
                  {
                    type: "email",
                    message: "Provide the valid email address",
                  },
                ]}
              >
                <Input placeholder="Email" size="large" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[{ required: true, message: "Password is required" }]}
              >
                <Input.Password
                  placeholder="Password"
                  type="password"
                  name="password"
                  size="large"
                />
              </Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Login
              </Button>
            </Form>
          </div>
        </Card>
      </div>
    </UnauthLayout>
  );
};

export default Login;
