import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const editPost = (postId, data) => {
  return client({
    method: "PATCH",
    url: PATHS.updatePost.replace(":postId", postId),
    data,
  });
};

export default editPost;
