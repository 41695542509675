import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { updateUser } from "services";

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation((data) => updateUser(data).then((res) => res.data), {
    onSuccess: (e, { userId, ...data }) => {
      queryClient.setQueryData(["user", userId], (old) => {
        const updatedSettings = {
          ...old,
          data: {
            ...old.data,
            ...data,
          },
        };
        return updatedSettings;
      });
    },
    onError: (err) => {
      message.error("Error in updating this setting");
    },
  });
};

export default useUpdateUser;
