import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";

export const updateUser = (data) => {
  return client({
    method: "PUT",
    url: PATHS.updateUser,
    data,
  });
};

export default updateUser;
