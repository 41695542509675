import DashboardLayout from "layout/DashboardLayout";
import styles from "./styles.module.scss";
import { Card, Skeleton, Switch, message } from "antd";
import { Button } from "ui-components";
import { useEffect, useState } from "react";
import { useSetting } from "hooks/useSetting";
import useSaveSetting from "hooks/useSaveSetting";

const defaultSetting = {
  POST_SCREENING: false,
};

export const Setting = () => {
  const { data, isLoading } = useSetting();
  const [settings, setSettings] = useState(data?.data || defaultSetting);
  const { mutate, isLoading: minorLoading } = useSaveSetting();

  useEffect(() => {
    if (data?.data) {
      setSettings(data?.data);
    }
  }, [data]);

  const onSaveSettings = async () => {
    mutate(settings);
  };

  const LoadingSkeleton = () => {
    return (
      <div className={styles.skeleton}>
        {Array.from({ length: 5 })
          .fill(4)
          .map((item) => (
            <div className={styles.item}>
              <Skeleton.Input active size="small" className={styles.key} />
              <Skeleton.Button active size="small" className={styles.switch} />
            </div>
          ))}
      </div>
    );
  };

  return (
    <DashboardLayout activeTab="4">
      <div className={styles.setting}>
        <div className={styles.header}>
          <div className="pageTitle">Settings</div>
          <Button
            type="primary"
            loading={minorLoading}
            onClick={onSaveSettings}
          >
            Save
          </Button>
        </div>
        <div className={styles.main}>
          <Card bordered={false} className={styles.card}>
            <div className={styles.header}></div>
            {isLoading ? (
              <div className={styles.body}>
                <LoadingSkeleton />
              </div>
            ) : (
              <div className={styles.body}>
                <div className={styles.item}>
                  <div className={styles.name}>Post Screening Required</div>
                  <div>
                    <Switch
                      defaultChecked
                      checked={settings?.POST_SCREENING}
                      onChange={(val) =>
                        setSettings((s) => ({ ...s, POST_SCREENING: val }))
                      }
                    />
                  </div>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Setting;
