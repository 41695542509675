import styles from "./styles.module.scss";
import { ReactComponent as Logo } from "assets/svg/logo.svg";

export const UnauthLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <Logo />
        </div>
        {children}
        <div className={styles.footer}>
          <p>© 2023 Escavo, inc. all right reserved</p>
        </div>
      </div>
    </div>
  );
};

export default UnauthLayout;
