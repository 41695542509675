import { ExclamationCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Modal, message } from "antd";
import { AddConfigurationModal } from "components/Modal/AddConfigurationModal";
import {
  DEFAULT_SPECIALITY_CURRENT_PAGE,
  DEFAULT_SPECIALITY_PAGE_SIZE,
} from "constants/configuration";
import { queryClient } from "context/ReactQueryContext";
import { useAddSpeciality } from "hooks/useAddSpeciality";
import { useDeleteSpeciality } from "hooks/useDeleteSpeciality";
import { useSpecialities } from "hooks/useSpecialities";
import useUpdateSpeciality from "hooks/useUpdateSpeciality";
import { useDeferredValue, useState } from "react";
import { Button, Pagination, Table } from "ui-components";
import styles from "./styles.module.scss";

export const Speciality = () => {
  const [addSpecialtyModalShow, setAddSpecialityModalShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    DEFAULT_SPECIALITY_CURRENT_PAGE
  );
  const [selectedItem, setSelectedItem] = useState(null);
  const [deleteModal, deleteModalContextHolder] = Modal.useModal();
  const [searchText, setSearchText] = useState("");
  const deferredSearchText = useDeferredValue(searchText);
  const openAddSpecialityModal = () => setAddSpecialityModalShow(true);
  const { data: paginatedData, isLoading } = useSpecialities(
    `${encodeURIComponent(deferredSearchText)}`,
    currentPage,
    DEFAULT_SPECIALITY_PAGE_SIZE
  );
  const { mutateAsync: createSpeciality, isLoading: minorLoading } =
    useAddSpeciality();
  const { isLoading: minorUpdateLoading, mutateAsync: editSpeciality } =
    useUpdateSpeciality();
  const { isLoading: minorDeleteLoading, mutateAsync: deleteSpeciality } =
    useDeleteSpeciality();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const closeAddSpecialityModal = () => {
    setAddSpecialityModalShow(false);
    setSelectedItem(null);
  };
  const columns = [
    {
      title: "Specialty",
      dataIndex: "speciality",
      key: "speciality",
    },
    {
      title: "Action",
      dataIndex: "speciality",
      key: "speciality",
      width: "18rem",
      render: (item, rowData) => {
        return (
          <div className={styles.actionColumn}>
            <Button
              type="primary"
              danger
              onClick={() => {
                setShowConfirmModal(true);
                if (!showConfirmModal) {
                  deleteModal.confirm({
                    title: "Delete Specialty",
                    icon: <ExclamationCircleOutlined />,
                    content: "Are you sure you want to delete this specialty?",
                    okText: "Yes",
                    cancelText: "No",
                    centered: true,
                    loading: minorDeleteLoading,
                    onOk: async () => {
                      try {
                        await deleteSpeciality(rowData?.id);
                        message.success("Successfully deleted");
                      } catch (error) {
                        console.log("Error in deleting the specialty");
                      } finally {
                        setShowConfirmModal(false);
                      }
                    },
                    onCancel: () => setShowConfirmModal(false),
                  });
                }
              }}
            >
              Delete
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setSelectedItem(rowData);
                setAddSpecialityModalShow(true);
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  const onAddNewSpeciality = async (values, form) => {
    const inputValue = values.input?.trim();
    try {
      if (selectedItem) {
        await editSpeciality({
          id: selectedItem?.id,
          name: inputValue,
        });
        queryClient.setQueryData(
          [
            "specialities",
            deferredSearchText,
            currentPage,
            DEFAULT_SPECIALITY_PAGE_SIZE,
          ],
          (old) => {
            const index = old.data.data.findIndex(
              (item) => item.id === selectedItem?.id
            );
            old.data.data[index].name = values.input;
            return old;
          }
        );
        message.success("Successfully updated");
      } else {
        await createSpeciality(values.input?.trim());
        message.success("Successfully created");
      }
      closeAddSpecialityModal();
      form.current.resetFields();
      setSelectedItem(null);
    } catch (error) {
      console.log("error in performing this error");
    }
  };

  return (
    <>
      <div className={styles.header}>
        <div className="pageTitle">Configuration</div>
        <div className={styles.filter}>
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            size="middle"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              setCurrentPage(DEFAULT_SPECIALITY_CURRENT_PAGE);
            }}
            allowClear
          />
          <Button type="primary" size="middle" onClick={openAddSpecialityModal}>
            Add New Specialty
          </Button>
        </div>
      </div>

      <div className={styles.table}>
        <Table
          columns={columns}
          dataSource={paginatedData?.data?.data?.map?.((item) => {
            return {
              key: item?.id,
              id: item?.id,
              speciality: item?.name,
            };
          })}
          defaultSkeletonRows={DEFAULT_SPECIALITY_PAGE_SIZE}
          loading={isLoading}
        />
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            total={paginatedData?.data?.total}
            showSizeChanger={false}
            onChange={(pagination) => {
              setCurrentPage(parseInt(pagination));
            }}
            pageSize={DEFAULT_SPECIALITY_PAGE_SIZE}
          />
        </div>
      </div>
      <AddConfigurationModal
        title={`${selectedItem ? "Edit" : "Add"} Specialty`}
        okText="Save"
        onCancel={closeAddSpecialityModal}
        open={addSpecialtyModalShow}
        cancelText="Cancel"
        label="Specialty"
        placeholder={`${selectedItem ? "Edit" : "Add new"} Specialty`}
        onOk={onAddNewSpeciality}
        loading={minorLoading || minorUpdateLoading}
        selectedItem={selectedItem}
        inputDefaultValue={selectedItem?.speciality || ""}
        centered
      />
      {deleteModalContextHolder}
    </>
  );
};

export default Speciality;
