import * as yup from "yup";

export const adminFeedbackSchema = yup.object().shape({
  feedbackText: yup
    .string()
    .trim()
    .max(1500, "Feedback must not exceed 1500 characters")
    .required("Feedback is required"),
});

export default adminFeedbackSchema;
