import client from "services/backend/client";
import PATHS from "services/backend/Paths";

export const updateGlobalSettings = (data) => {
  return client({
    method: "PATCH",
    url: PATHS.updateSettings,
    data,
  });
};

export default updateGlobalSettings;
