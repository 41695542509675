import { message } from "antd";
import axios from "axios";
import refreshSession from "./apis/refreshSession";

export const client = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

client.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});
client.interceptors.response.use(
  (response) => response,
  async function (error) {
    if (error.response.status === 401) {
      try {
        const data = await refreshSession(localStorage.getItem("refreshToken"));
        const token = data.data.data.AccessToken;
        const config = error.config;
        localStorage.setItem("token", token);
        config.headers["Authorization"] = `Bearer ${token}`;
        return axios(config);
      } catch (error) {
        message.error("Session has been expired. Please login again");
        if (!localStorage.getItem("refreshToken")) window.location.href = "/";
      }
    } else {
      return Promise.reject(error);
    }
  }
);
export default client;
