import client from "services/backend/client";
import { PATHS } from "services/backend/Paths";

export const verifyAdmin = () => {
  return client({
    method: "GET",
    url: PATHS.verifyAdmin,
  });
};

export default verifyAdmin;
